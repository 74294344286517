<template>
    <div>
        <vx-card title="Payment Collection">
            <vs-tabs :color="colorx" v-model="tabs">
                <vs-tab @click="colorx = 'danger'" label="Open">
                    <div class="con-tab-ejemplo">
                        <open/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Receive">
                    <div class="con-tab-ejemplo">
                        <collect/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Received">
                    <div class="con-tab-ejemplo">
                        <receive/>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>
<script>
import open from "./open.vue";
import collect from "./collect.vue";
import receive from "./receive.vue";

export default {
    components: {
        open,
        receive,
        collect
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
    })
}
</script>