<template>
  <div>
    <table class="table">
      <thead>
        <th>Invoice Code</th>
        <th>Amount</th>
      </thead>
      <template v-for="line in lines">
        <tr v-bind:key="line.ID">
          <td>{{ line.Code }}</td>
          
          <td>{{formatPrice(line.Total.toString())}}</td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
import { collectCashLogLine } from "../../../../services/api/invoice";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
  },
  components: {},
  data() {
    return {
      lines: [],
      params: {
        search: "",
        length: 100,
        page: 1,
        order: "desc",
        sort: "id",
      },
    };
  },
  computed: {},
  watch: {
    selected() {
      console.log(this.selected);
    },
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("MM/DD/YYYY hh:mm");
      }
    },
    reloadData() {
      // this.params = params
      this.params.ID = this.selected.ID
      console.log(this.selected)
      this.$vs.loading();
      const creditNote = collectCashLogLine(this.params);
      creditNote.then((r) => {
        this.$vs.loading.close();
        console.log(r);
        this.lines = r.data.payment;
      });
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      split[0] = parseInt(split[0]).toString();
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>