<template>
  <div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
          <vs-td>
            {{ tr.ReferenceCode }}
          </vs-td>
          <vs-td>
            {{ tr.PaymentCode }}
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.PaymentAmount) }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import { dataPaymentCollection } from "../../../../services/api/invoice";
export default {
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Invoice",
          value: "reference_code",
          // width: '5%'
        },
        {
          text: "Payment Code",
          value: "payment_code",
        },
        {
          text: "Amount",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = dataPaymentCollection(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
      // this.$http
      //   .get("api/v1/invoice/data-table", {
      //     params: {
      //       search: params.search,
      //       length: params.length,
      //       page: params.page,
      //       order: params.order,
      //       sort: params.sort,
      //       territory_id: this.selected.territory.id,
      //       status: 1,
      //     },
      //   })
      //   .then((resp) => {
      //     if (resp.code == 500) {
      //       this.$vs.loading.close();
      //     } else if (resp.code == 200) {
      //       this.$vs.loading.close();
      //       this.data = resp.data.invoices;
      //       this.responseData = resp.data;
      //       this.checkedAll = false;
      //     } else {
      //       this.$vs.loading.close();
      //     }
      //   });
      // this.$http
      // .get("/api/v1/master/price-rule", {
      //   params: {
      //     search: params.search,
      //     length: params.length,
      //     page: params.page,
      //     order: "",
      //     sort: "",
      //   }
      // })
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>